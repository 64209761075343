@mixin segment_height($child) {
    @if($child == "list") {
        .ui.stacked.segment {
            height: 100% !important;
        }
    } @else if($child == "map") {
        .ui.stacked.segment {
            height: 100vh !important;
        }
    }
}

.pushable {
    // height: 100%;
    >.pusher {
        // max-height: 100%;
        &:after{
            display:none;
        }
    }
}

.ui.stacked.segment {
    height: 100% !important;
    min-height: 100vh !important;
    &:after {
        display:none;
    }
}
.ui.segment {
    border: none !important;
    border-radius: 0 !important;
}

main {
    //top: 100px !important;
    margin: 0 auto;
    //width: 90%;
}

.auth-container.main-container {
    max-height: 100vh !important;
}

.ui.dropdown > .dropdown.icon {
    font-family: Dropdown !important;
}

.ui.rating .icon {
    font-family: Rating !important;
}

i.icon {
    font-family: Icons !important;
}


.close-popup {
    position: fixed;
    right: 18px;
    top: 18px;
    width: 12px;
    height: 12px;

    > svg {
        width: 12.82px;
    }
}

.popupContent {
    width: 236px;
    height: 98px;
    font-size: 16px;
    font-family: Roboto !important;
    font-weight: 400;
    text-align: center;
    letter-spacing: -0.166667px;
}

.ui.top.center.popup.transition.visible {
    left: auto;
    right: auto;
    position: initial;
    background: #FFFFFF;
    box-shadow: 0 2px 34px rgb(39 52 125 / 17%);
    backdrop-filter: blur(7.61483px);
    border-radius: 12px;
}

.ui.top.center.popup:before {
    width: 20px;
    height: 20px;
    bottom: -10px !important;
    margin-left: -10px !important;
}