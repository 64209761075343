.followup {
  display: flex;
  flex-direction: column;
  margin-left:12px;
  margin-top: 12px;
  margin-bottom: 24px;
  .questions-followup {
    width: 100%;
    text-align: left;
    border: none;
  }

  .followup-label {
    margin-bottom: 12px;
    text-align: left;
    display: block;
    font-weight: 500;
    //margin-left: 35px !important;
  }
  .followup-input {
    .input-field-input {
      height: 24px;
      // font-size: 16px;
      // font-weight: normal;
      // font-stretch: normal;
      // font-style: normal;
      // line-height: 1.5;
      // letter-spacing: normal;
      // color: #000;
      border-bottom: solid 1px #b2b2b2;
      border-top: none;
      border-left: none;
      border-right: none;
      //margin-bottom: 0px;
      //margin-top: 12px;
      width: 100%;
      display: block;
      text-align: left;
    }
  }
}