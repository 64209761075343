// #review-modal {
//   width: 100vw !important;
// }

.review-verify {
  //font-family: EuclidWU;
  margin: 0 auto;

  .instructions {
    display: block;
    width: 100%;
    padding: 0 1em;
    height: 100%;
    margin: 0 auto 2em;
  }

}

// .ui.animating.dimmer, .dimmed.dimmable > .ui.visible.dimmer, .ui.active.dimmer {
//   padding: 0 !important;
//   width: 100% !important;
// }
