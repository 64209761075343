p#review-title {
  margin-bottom: 24px;
}

.review-section {
  //max-width: 90%;
  padding-left: 1em;
  padding-right: 1em;
  margin: 0 auto 24px !important;
  padding-bottom: 12px;
  // font-family: EuclidWU;
  border-bottom: 1px solid var(--brown-grey);

  // h2 {
  //   //margin: 0 0 1em !important;
  //   font-size: 16px;
  //   font-weight: 500;
  //   font-stretch: normal;
  //   font-style: normal;
  //   line-height: normal;
  //   letter-spacing: normal;
  //   color: var(--primary-black);
  // }

  // .section-title {
  //   margin-right: 10px !important;
  // }

  // p {
  //   text-align: left !important;
  //   line-height: 1.5;
  // }

  .review-address, .review-hours, .review-questions {
    display: flex;
    flex-direction: column;
    width: 100%;
    margin: 0;


    ul {
      list-style: none;
      margin-left: 0;
      padding-left: 0;

      li {
        display: grid;
        width: 100%;
        justify-content: left !important;
        text-align: left;
        grid-template-columns: .4fr .6fr;
        margin-bottom: 6px;

        .label {
          justify-content: left;
          text-align: left;
          // line-height: 1.5;
        }

        .value {
          justify-content: right;
          text-align: right;
          // line-height: 1.5;
        }
      }
    }

    ol{
      list-style: decimal;
      display: block;
      width: 100%;
      margin: 0;
      padding-left: 20px;
      justify-content: left !important;
      text-align: left;
      line-height: 1.5;
      li {
        line-height: 1.5;
        font-size: 14px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        letter-spacing: normal;
        color: var(--primary-black);
        margin: 0 0 24px;

        .question {
          display: block;
          width: 100%;
          justify-content: left !important;
          text-align: left;
          color: var(--greyish-brown);
        }

        .response {
          display: block;
          width: 100%;
          text-align: left;
          margin-top: 12px;
          font-style: italic;
          color: var(--greyish-brown);
        }

        &:last-child {
          margin: 0;
        }
      }
    }
  }

  .section-title {
    display: flex;
    flex-direction: row;
    margin: 0 auto 12px;

    h2 {
      width: 100% !important;
      margin: 0 !important;
      padding: 0;
      float: left;
      text-align: left;
    }

    .edit-icon {
      float: right;
      display: block;
    }
  }

  .card-hidden {
    display: none;
  }

  .add-photos .grid {
    margin: 0;
  }
}