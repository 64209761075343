.location-list {
  //margin-top: 50px;
  min-height: 99vh;
  overflow: auto;

  h1.title {
    display: block;
    margin: 84px auto 24px !important;
    max-width: 80%;
  }

  ul {
    margin: 30px auto 0 auto;
    list-style-type: none;
    width: 100%;
    padding: 0px;

    li {
      display: block;
      margin: 0 auto;
      width: 98%;
      margin-bottom: 1em;
    }
  }
}

.location-container-map {
  text-align: left;
  margin-top: calc(64px - 1em);
  //height: 97vh !important;
}

.brand-logo-placeholder {
  width: 100% !important;
  display: block;
  position: absolute;
  height: 100px !important;
  margin: 100px 0 auto 5%;
}

// marker
.gm-style .gm-style-iw-c {
  position: absolute;
  box-sizing: border-box;
  overflow: hidden;
  top: 8px;
  left: 0;
  transform: translate3d(-54%, -129%, 0);
  background-color: white;
  border-radius: 8px;
  padding: 12px;
  box-shadow: 0 2px 7px 1px rgb(0 0 0 / 30%);
  height: max-content;
}

.gm-style .gm-style-iw-tc::after {
  top: -4px !important;
}

a {
  display: inline-block;
  color: var(--primary-black) !important;

  &.directions {
    display: block;
    margin-top: 30px;
    color: var(--secondary-sapphire) !important;
    font-weight: 600 !important;
    text-align: left;
  }
}

button.gm-ui-hover-effect {
  right: 0 !important;
  top: 0 !important;
  background: linear-gradient(0deg, rgba(255, 255, 255, 0.09), rgba(255, 255, 255, 0.09)), var(--primary-yellow) !important;
  box-shadow: 0 2px 3px rgba(0, 0, 0, 0.14), 0 1px 5px rgba(0, 0, 0, 0.12), 0 1px 2px -1px rgba(0, 0, 0, 0.2);
  border-radius: 50px !important;
  color: var(--primary-black);
  width: 33px !important;
  height: 33px !important;

  span {
    content: url("../../assets/images/icon-closecard.svg") !important;
    width: 30px !important;
    height: 30px !important;
    margin: 0 auto !important;
  }
}

.gm-style-iw {
  height: 316px;
  margin: 94px 73px 151px 74px;
  padding: 0 0 0.3px;
  -webkit-backdrop-filter: blur(5.6px);
  backdrop-filter: blur(5.6px);
  box-shadow: 0 2px 34px 0 rgba(39, 52, 125, 0.17);
  background-color: #fff;
  //font-family: EuclidWU;

  img {
    display: inline-block;
    width: 100%;
    height: 110px;
  }

  .infowindow {
    text-align: left !important;
    justify-content: left;
    margin: 16px 8px 8px 2px;
    line-height: 1.5;

    img {
      background-color: var(--primary-white) !important;
    }

    .infowindow-box {
      display: flex;
      flex-direction: column;
      .infowindow-data {
        top: 0 !important;
        text-align: left;
        .infowindow-link {
          display: block;
          width: 100%;
          height: 100%;
          cursor: pointer;
          color: var(--secondary-sapphire);
          text-transform: lowercase !important;

          .title {
            width: 100%;
            //height: 16px;
            margin: 0 20px 5px 0;
            font-size: 16px;
            font-weight: 500;
            font-stretch: normal;
            font-style: normal;
            line-height: normal;
            letter-spacing: normal;
            color: var(--primary-black) !important;
            text-transform: capitalize !important;
            text-align: left;
            justify-content: left;
          }

          p {
            width: 180px;
            height: 42px;
            margin: 5px 0 15px;
            font-size: 14px;
            font-weight: normal;
            font-stretch: normal;
            font-style: normal;
            line-height: 1.5;
            letter-spacing: normal;
            color: var(--primary-black) !important;
            text-transform: capitalize !important;
          }
        }
      }
      .infowindow-directions {
        margin: 0 20px 20px 0;
        justify-content: flex-start;
      }
      .infowindow-operations {
        display: inline-block;
        width: fit-content;
        justify-content: flex-start;
        margin: 0 10px 10px 0;
      }
    }


  }
}

/* label */
.banner {
  /* Auto layout */
  display: flex;
  flex-direction: column;
  align-items: center;
  // padding: 20px;
  // gap: 10px;
  isolation: isolate;

  position: fixed;
  width: 335px;
  height: 74px;
  margin: 0 auto 0 auto;
  bottom: 12%;

  left: 50%;
  transform: translateX(-50%);



  .rectangle3 {
    /* Rectangle 3 Copy */

    // position: absolute;
    // left: 0;
    // right: 0;
    // top: 0;
    // bottom: 0;

    background: #94CBF3;
    backdrop-filter: blur(7.61483px);
    /* Note: backdrop-filter has minimal browser support */
    border-radius: 12px;

    /* Inside auto layout */
    flex: none;
    order: 0;
    flex-grow: 0;
    z-index: 0;
  }

  .text {
    /* Ocean Park */
    margin: 15px;
    // width: 295px;

    font-size: 16px;

    color: #000000;


    /* Inside auto layout */
    flex: none;
    order: 1;
    flex-grow: 0;
    z-index: 1;
  }
}

.switch-views {
  position: absolute;
  display: block;
  bottom: calc(128px + 2.5vh);
  margin: 0 auto;
  width: 100%;
}

// @media only screen and (max-height: 570px) {
//   .switch-views {
//     position: relative;
//     display: block;
//     top: 70vh !important;
//     margin: 0 auto;
//     width: 100%;
//   }
// }

@media (max-width: 330px) {
  .location-list {
    li {
      .location-row {
        img {
          display: none;
        }
      }
    }
  }
  .location-container-map {
    margin-top: 50px !important;

    .address-autocomplete {
      z-index: 99;
      width: 85%;
      margin-left: 38px !important;
    }

    // .switch-button {
    //   display: grid;
    //   grid-template-columns: 155px 1fr;
    //   grid-template-rows: 52px 1fr;
    //   max-width: 200px;
    //   text-align: center;
    // }
  }
}