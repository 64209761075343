@import url('assets/styles/colors.css');
@import url('assets/styles/fonts.css');

html, body {
    margin: 0;
    padding: 0;
}

body {
    display: block;
    justify-content: center;
    align-items: center;
    min-height: 100%;
    height: 100%;
    font-family: Roboto, Arial, Helvetica, sans-serif !important;
    text-rendering: optimizeLegibility;
}

h1, h2, h3, h4, h5 {
    font-family: PPRightGrotesk, sans-serif !important;
}

h1, h2, h3, h4, h5, h6, p, a, li, span .ui.label {
    line-height: 1.4;
    letter-spacing: -.01em;
    font-weight: 400;
    color: var(--primary-black);
}

p, li, span .ui.label, input {
    font-size: 14px;
    font-family: Roboto, sans-serif !important;
}

h1 {
    font-size: 1.75rem;
    font-weight: 700;
}

h2 {
    font-size: 1.5rem;
    font-weight: 500;
}

h3 {
    font-size: 1.25rem;
    font-weight: 500;
}

sup {
    font-size: 50%;
    position: relative;
    top: auto;
    vertical-align: super;
}

label {
    text-align: left;
}

.App {
    height: 100% !important;
}

/* h1 {
    font-size: 2rem;
    text-align: center;
} */

img {
    border-radius: 5px;
    height: auto;
    width: 10rem;
}

div {
    text-align: center;
}

small {
    display: block;
}

a {
    text-decoration: none;
    font-family: Roboto, sans-serif;
}

:focus-visible {
    outline: none;
}

.ui.selection.active.dropdown, .ui.selection.active.dropdown:hover, .ui.selection.active.dropdown .menu, .ui.selection.active.dropdown:hover .menu {
    border: solid 1px #27347d;

}

.ui.selection.active.dropdown, .ui.selection.active.dropdown:hover {
    border-top-left-radius: 6px !important;
    border-top-right-radius: 6px !important;

}


.hidden {
    display: none !important;
}

.ui.image {
    display: block;
    text-align: center;
    padding: 0;
    margin: 0 auto;
}

.icon-info {
    display: block;
    width: fit-content;
    text-align: center;
    margin: 0 auto 0 auto;
}

.contextRef {
    position: absolute;
    top: 99vh;
    display: block;
    margin: 0 auto 0 auto;
    width: 100%;
}

/****************************************************/
@keyframes App-logo-spin {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
}

.ui.container {
    min-width: 100vw;
}

.loader {
    position: absolute;
    top: calc(100vh / 2);
    width: calc(100vw / 2);
}