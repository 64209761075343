@import "../../assets/styles/fonts.css";
@import "../../assets/styles/colors.css";

.home-search {
  margin-top: 48px;
  padding: 0 5% 0;
  //font-family: EuclidWU;
  //height: 90vh;

  .mask {
    display: block;
    position: absolute;
    top: 0;
    left: 0 !important;
    width: 100%;
    height: 100%;
    z-index: 1099;
    opacity: 0.2;
    background-color: var(--secondary-sapphire);

    &.disabled {
      z-index: -9 !important;
      visibility: hidden !important;
    }
  }

  .marketing-banner {
    display: block;
    margin: 0 auto;
    width: 100vw;
    align-content: center;
    text-align: center;
    bottom: 15px;
    position: absolute;

    >img {
      width: max-content;
    }
  }

  .home-inputs {
    margin: 10px;
    padding-bottom: 25px;
  }

  .title {
    display: inline-block;
    width: 100%;
    height: 21px;
    margin: 0 auto 7px auto;
    font-size: 21px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: -0.44px;
    text-align: center;
    color: var(--primary-black);
  }

  .subtitle {
    display: inline-block;
    width: 80%;
    height: 21px;
    margin: 7px auto 30px auto;
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.5;
    letter-spacing: normal;
    text-align: center;
    color: var(--primary-black);
  }



  .search-inputs {
    min-height: 7em;
  }

  .OR {
    display: inline-block;
    width: 100%;
    //height: 18px;
    margin: 2em auto 2em auto;
    //font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.5;
    letter-spacing: normal;
    text-align: center;
    color: var(--primary-black);
  }

  .income-banner {
    max-height: 60% !important;
    min-width: 50% !important;
    margin: 0 auto !important;
    display: inline-block !important;
  }

  label {
    margin: 20px auto 20px auto !important;
    text-align: left !important;
    padding: 0 !important;
    display: block;
    width: 80%;
    height: 12px;
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: 0.25px;
    color: #767676;
    background-color: transparent !important;
  }
  .input-name {
    height: 24px;
    font-size: 16px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.5;
    letter-spacing: normal;
    color: #000;
    border-bottom: solid 1px rgba(0, 0, 0, 0.2);
    border-top: none;
    border-left: none;
    border-right: none;
    margin-bottom: 10px;
    margin-top: 25px;
    margin-left: 15px;
    width: 80%;
  }
}

// .ui.image {
//   margin: 0 auto !important;
// }

@media screen and (min-width: 815px) {
  .home-search {
    .home-inputs {
      margin: 10px;
      padding-bottom: 25px;

      .auto {
        width: 100%;
        margin: 0 auto;
        display: block;
        height: 63px;
        text-align: center;
        justify-content: center;
        align-content: center;
        padding: 4%;
      }
    }
  }
}