@import "../../assets/styles/colors.css";
@import "../../assets/styles/fonts.css";

.hamburger-div {
  z-index: 9;
  display: flex;
  justify-content: flex-end
}

.SideMenu {
  height: 100%;

  > .ui.sidebar {
    overflow: hidden !important;
  }

  .hamburger-div {
    > div > div {
      background-color: var(--primary-yellow) !important;
    }
  }

  .ui.inverted.icon.menu {
    .item {
      color: var(--primary-yellow);
    }
  }

  .ui.visible.push.sidebar {
    max-width: 75% !important;
    max-height: 100% !important;
    z-index: 999 !important;
  }

  .ui.vertical.inverted.menu {
    .item:before, .item:after {
      background: none;
    }
  }

  .ui.menu {
    .item {
      text-align: left !important;
      padding: 0 0 0 30px;
      margin-bottom: 1em;

      .ui.selection.dropdown {
        background: none !important;
        color: var(--primary-yellow) !important;
        padding-left: 0;

        .flag {
          width: 20px !important;
          height: 20px !important;
          object-fit: contain;
          border-radius: 50%;
        }
      }

      &.profile {
        display: block;
        width: 100%;
        min-height: 12px;
        margin-bottom: 12px;
        //margin-left: 30px;

        .profile-name {
          display: table-cell;
          vertical-align: middle;
          height: 37px;
          //font-family: EuclidWU;
          font-size: 12px;
          font-weight: 500 !important;
          font-stretch: normal;
          font-style: normal;
          line-height: 1 !important;
          letter-spacing: 1.5px;
          color: #a7a7a7 !important;
        }
      }

      &.menu-option {
        font-size: 21px;
        font-weight: 500 !important;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.29 !important;
        letter-spacing: -0.44px;
        color: var(--primary-yellow) !important;
      }

      &.menu-option-small {
        font-size: 12px;
        color: var(--primary-white) !important;

        button {
          color: var(--primary-white) !important;
          border: none;
          background: none;
          padding: 0;
        }
      }

      &.copyright {
        font-size: 10px;
        font-weight: 500 !important;
        font-stretch: normal;
        font-style: normal;
        line-height: 2.1 !important;
        letter-spacing: -0.21px;
        color: var(--brown-grey) !important;
        height: 21px !important;
        bottom: 40px !important;
        position: absolute !important;
        margin-left: 40px;
      }
    }

  }

}

@media screen and (max-height: 812px) {
  .SideMenu {
    .ui.visible.push.sidebar {
      max-width: 75% !important;
    }
  }
}
