.questions-dropdown {
  // margin: 20px auto 20px auto;
  // text-align: left !important;
  // width: 80%;
  margin-bottom: 24px;

  >.ui.fluid.selection.dropdown {
    text-align: left !important;
    height: 40px;
    padding: 12px 19px 12px 12px;
    border-radius: 20px;
    border: solid 1px #27347d;
    display: block;
    min-width: 200px !important;

    &:hover {
      border-radius: 6px;
    }
  }


  .questions-dropdown .dropdown .menu {
    border-top-left-radius: 6px !important;
    border-top-right-radius: 6px !important;
  }

  // .ui.active.selection.dropdown {
  //   margin-top: 15px !important;
  // }
}