.location-details {
  width: 100%;

  > img {
    display: inline-block;
    width: 100%;
    height: 216px;
    background-color: rgba(45, 78, 157, 0.26);
  }


  .back-link {
    position: absolute;
    left: 17px;
    top: calc(152px + .5em);
    font-size: 14px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: right;
    color: var(--primary-black);
    &::before {
      content: "< ";
    }
  }

  .location-details-container{
    margin-top: -4rem;
  }

  .review-type-popup {
    height: fit-content;
    margin: 0 auto 2em;
    width: 90%;
    padding: 30px 20px 40px;
    border-radius: 12px;
    -webkit-backdrop-filter: blur(5.6px);
    backdrop-filter: blur(5.6px);
    box-shadow: 0 2px 34px 0 rgba(39, 52, 125, 0.17);
    background-color: var(--primary-white);
    justify-content: center;

    h1 {
      font-family: 'Roboto' !important;
    }

    #review-type-selector{
      margin-bottom: 2em;
      border-radius: 20px;

      .default.text {
        color: #000;
      }

      .menu {
        max-height: 140px;
      }
    }

    > select {
      width: 80%;
      height: 40px;
      margin: 0 0 20px;
      padding: 4px 5px 12px 18px;
      border-radius: 46px;
      border: solid 1px #27347d;
      background-color: #fff;
    }
  }
}

.review-hours {
  display:flex;
  margin: 0 auto 24px;
  width: 100%;
  .hours {
    display: block;
    margin: 0 auto 0 auto;
    border-radius: 12px;
    -webkit-backdrop-filter: blur(5.6px);
    backdrop-filter: blur(5.6px);
    box-shadow: 0 2px 34px 0 rgba(39, 52, 125, 0.17);
    background-color: #fff;
    > svg {
      width: 100%;
      object-fit: contain;
    }
  }
  
  .hours-row{
    width: 90%;
    margin: 0 auto;
    padding: 20px 24px 16px 20px;
    border-radius: 12px;
    -webkit-backdrop-filter: blur(5.6px);
    backdrop-filter: blur(5.6px);
    box-shadow: 0 2px 34px 0 rgba(39, 52, 125, 0.17);
    background-color: #fff;
    text-align: left !important;
    color: var(--primary-black);
    
    .btn-location-update-hours {
      background:none;
      border:none;
      display: flex;
      align-items: center;
      align-content: center;
      flex-wrap: nowrap;
      justify-content: space-between;
      width:100%;
      
      .info-icon {
        width: 32px;
        height: 32px;
        background-image: url("../../assets/images/info-icon.svg");
        margin-right: 2em;
      }
      .review-hours-copy{

        text-align: left;
        p {
          margin-bottom:6px;

          &:last-child{
            margin-bottom:0px;
          }
        }

        .review-hours-CTA {
          color: var(--secondary-sapphire);
        }
      }
      .arrow-left {
        width: 16px;
        height: 16px;
        margin-left:2em;
        background-image: url("../../assets/images/0021-chevron-right.svg");
      }
    }
  }
  
}

.review-location {
  margin: 0 auto 30px auto;
  width: 100%;
}

// Modal

.ui.dimmer {
  background-color: rgba(255,255,2255, 255) !important;
}

.ui.basic.modal {
  color: black !important;
  left: auto !important;
}

.ui.fullscreen.scrolling.modal {
  left: auto !important;
  width: 100% !important;
}

.ui.modal > .content {
  margin-top: calc(64px - 1em);
  padding: 0 !important;

  h1,h2,h3 {
    margin-bottom: 24px;
  }
}

i.close.icon {
  color: var(--primary-black) !important;
}

.action-box {
  .Button {
    .btn {
      width:90%;
      margin-bottom:24px;
    }
  }

      
}
