.location-row {
  //font-family: EuclidWU;
  width: 97%;
  display: flex;
  position: relative;

  margin: 0 auto;
  border-radius: 12px;
  background-color: #fff;
  text-align: left !important;
  color: var(--primary-black);

  img {
    display: block;
    width: 91px;
    height: 91px;
    margin: 0 19px 4px 0;
    background-color: var(--primary-white);
    float: left;
  }
  a {
    display: inline-block;
    color: var(--primary-black) !important;
    font-weight: 400 !important;
    font-size: 16px;
    line-height: 18.75px;
    &.directions {
      margin-top: 0 !important;
      color: var(--secondary-sapphire) !important;
      font-size: 11px !important;
      display: inline-block;
    }
    .directions-row {
      display: block;
      font-size: 11px !important;
      margin-top: 13px !important;
      > div {
        display: inline-block;
      }
    }
  }

  .row-details {
    display: block;
    text-align: left;
    text-transform: lowercase !important;

    div {
      text-align: left;
      text-transform: capitalize;
    }

    h3 {
      display: block;
      margin: 0;
      text-transform: capitalize !important;
      font-size: 16px;
      font-weight: 400;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: normal;
      color: var(--primary-black);
    }

    p, span {
      margin: 0;
      font-size: 14px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.5;
      letter-spacing: normal;
      color: var(--primary-black);
      text-transform: capitalize;
    }

    .edit-address-icon {
      display: inline-block;
      height: 100%;
      right: 10px;
      cursor: pointer;
    }
  }
}

.item-link {
  display: inline-block;
  width: 100%;
}

.update-address {
  margin: 0 !important;
  padding: 0 !important;
  height: 200px;

  @media only screen and (max-width: 767px) {
    .ui.form .fields {
      flex-wrap: nowrap !important;
    }
  }

  .input-field-select {
    > label {
      text-align: left !important;
      padding-left: 16px !important;
      height: 12px !important;
      margin: 35px 35px 0 0 !important;
      //font-family: EuclidWU !important;
      font-size: 12px !important;
      font-weight: normal !important;
      font-stretch: normal !important;
      font-style: normal !important;
      line-height: normal !important;
      letter-spacing: 0.25px !important;
      color: #767676 !important;
    }
    >.selection.dropdown {
      width: 80% !important;
      text-align: left !important;
      height: 40px;
      padding: 12px 19px 12px 16px;
      margin-left: 12px;
      margin-top: 15px;
      border-radius: 46px;
      border: solid 1px #27347d;
      display: block;
      min-width: 136px !important;
    }
  }

  .input-field-input {
    > label {
      text-align: left !important;
      padding-left: 16px !important;
      height: 12px !important;
      margin: 35px 35px 0 0 !important;
      //font-family: EuclidWU !important;
      font-size: 12px !important;
      font-weight: normal !important;
      font-stretch: normal !important;
      font-style: normal !important;
      line-height: normal !important;
      letter-spacing: 0.25px !important;
      color: #767676 !important;
    }
    input[type="text"] {
      height: 24px;
      font-size: 16px;
      font-weight: normal !important;
      font-stretch: normal !important;
      font-style: normal !important;
      line-height: 1.5 !important;
      letter-spacing: normal !important;
      border: none !important;
      border-bottom: solid 1px rgba(0, 0, 0, 0.2) !important;
      margin-bottom: 10px !important;
      margin-top: 25px !important;
      margin-left: 15px !important;
      width: 80% !important;
      color: var(--primary-black);
    }
  }
}

.ui.header {
  height: 100%;
}

.update-address-header {
  display: grid;
  position: absolute;
  top: 0;
  left: 0;
  height: 200px;
  width: 100%;
  color: black !important;
  background-color: rgba(#2d4e9d,0.2) !important;
  margin-bottom: 25px;
  a {
    margin: 15px auto auto 15px;
    text-align: left;
    height: 16px;
    grid-column: 1;
    grid-row: 1;
    vertical-align: middle !important;
  }
  p {
    grid-column: 1;
    grid-row: 2;
  }
}

.update-address-content {
  //top: 100px !important;
  margin-top: 42px !important;
  height: 100vh;
  text-align: left !important;
  > p {
    margin-left: 16px;
  }
  > h1 {
    margin-left: 16px;
  }
}

.ui.fullscreen.modal {
  margin: 0 !important;
}

.update-address-modal-header {
  display: block !important;
  height:  100px !important;
  margin-bottom: 0 !important;
  padding: 0 !important;
  max-height: fit-content !important;
  width: 100vw;
  max-width: 110vw;
}
