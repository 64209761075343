/**
 * @license
 * Generated by Zeplin
 * Copyright (C) 2021 - present Zeplin
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at

 *     http://www.apache.org/licenses/LICENSE-2.0

 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

:root {
  --black: #262626;
  --brown-grey: #a7a7a7;
  --brownish-grey: #626262;
  --brownish-grey: #757575;
  --primary-white: #fff;
  --greyish-brown: #454545;
  --primary-black: #000;
  --very-light-pink: #e7e7e7;
  --very-light-pink: #d6d6d6;
  --pale-grey: #ebedf7;
  --secondary-sapphire: #2d4e9d;
  --primary-yellow: #fd0;
  --deep-blue: rgba(33, 44, 104, 0.97);
  --material-icon-dark: rgba(0, 0, 0, 0.54);
  --secondary-shale: #35b6b4;
  --material-status: rgba(0, 0, 0, 0.2);
  --secondary-sapphire-100: #adbfe8;
  --secondary-sapphire-200: #85a0dd;
  --system-safe-100: #dbf2fb;
  --wu-orange: #f39200;
  --wu-black: #0a0005;
  --wu-green: #63b55b;
  --wu-black-25: #c2bfc0;
  --system-gray: #9e9e9e;
}