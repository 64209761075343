.questions-input {
  // width: 80%;
  // margin: 20px auto 20px auto;
  // text-align: left !important;
  margin-bottom: 24px;

  .input-field-input {
    height: 24px;
    // font-size: 16px;
    // font-weight: normal;
    // font-stretch: normal;
    // font-style: normal;
    // line-height: 1.5;
    // letter-spacing: normal;
    // color: #000;
    border-bottom: solid 1px #b2b2b2;
    border-top: none;
    border-left: none;
    border-right: none;
    margin-bottom: 0px;
    //margin-top: 24px;
    width: 100%;
    //margin-left: 12px;
    //width: calc(100% - 12px);
    overflow: hidden;
    resize: none;
  }
}