/**
 * @license
 * Generated by Zeplin
 * Copyright (C) 2021 - present Zeplin
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at

 *     http://www.apache.org/licenses/LICENSE-2.0

 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

@font-face {
  font-family: PPRightGrotesk;
  src: url(../font/PPRightGrotesk/PPRightGrotesk-WideMedium.ttf) format("truetype");
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
}

@font-face {
  font-family: PPRightGrotesk;
  src: url(../font/PPRightGrotesk/PPRightGrotesk-WideBlack.ttf) format("truetype");
  font-weight: 700;
  font-style: normal;
  font-stretch: normal;
}

@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/roboto/v30/KFOmCnqEu92Fr1Mu4mxKKTU1Kg.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/roboto/v30/KFOlCnqEu92Fr1MmEU9fBBc4AMP6lQ.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

/* @font-face {
  font-family: EuclidWU;
  src: local(EuclidWULight),
    url(../font/EuclidWU/TTF/WebXL/EuclidWU-Bold-WebXL.woff2) format("woff2"),
    url(../font/EuclidWU/TTF/WebXL/EuclidWU-Light-WebXL.woff) format("woff"),
    url(../font/EuclidWU/TTF/ttf_desktop/EuclidWU-Light.ttf) format("truetype");
  font-weight: 300;
  font-style: normal;
  font-stretch: normal;
}

@font-face {
  font-family: EuclidWU;
  src: local(EuclidWU),
    url(../font/EuclidWU/TTF/WebS/EuclidWU-Regular-WebS.woff2) format("woff2"),
    url(../font/EuclidWU/TTF/WebS/EuclidWU-Regular-WebS.woff) format("woff"),
    url(../font/EuclidWU/TTF/ttf_desktop/EuclidWU-Regular.ttf) format("truetype");
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
}

@font-face {
  font-family: EuclidWU;
  src: local(EuclidWUMedium),
    url(../font/EuclidWU/TTF/WebXL/EuclidWU-Medium-WebXL.woff2) format("woff2"),
    url(../font/EuclidWU/TTF/WebXL/EuclidWU-Medium-WebXL.woff) format("woff"),
    url(../font/EuclidWU/TTF/ttf_desktop/EuclidWU-Medium.ttf) format("truetype");
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
} */

/* .the-biggest-title-2 {
  font-size: 30px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 0.69;
  letter-spacing: -0.63px;
  color: var(--primary-black);
}

.the-biggest-title {
  font-size: 30px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.13;
  letter-spacing: -0.63px;
  color: var(--primary-black);
}

.LaptopH5 {
  font-size: 24px;
  font-weight: 300;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.33;
  letter-spacing: -0.4px;
  color: var(--wu-black);
}

.Symbol-OrganizerGroup-Title {
  font-size: 20px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.2;
  letter-spacing: normal;
  color: var(--primary-black);
}

.h2blackleft {
  font-size: 16px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: var(--primary-black);
}

.h2whitecenter {
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: var(--primary-white);
}

.Normal-Text {
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.3;
  letter-spacing: -0.65px;
  text-align: center;
  color: var(--primary-black);
}

.textparagraph-text-link-2 {
  font-size: 14px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.29;
  letter-spacing: -0.1px;
  color: #368abb;
}

link {
  font-size: 14px;
  font-weight: 400;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: right;
  color: var(--secondary-sapphire);
}

.textparagraph-text-link {
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.29;
  letter-spacing: -0.1px;
  color: #368abb;
}

.descgraycenter {
  font-size: 12px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.25px;
  text-align: center;
  color: #9fa7b3;
} */