.questions-boolean {
  // width: 80%;
  // margin: 20px auto 20px auto;
  // text-align: left !important;
  margin-bottom: 24px;

  .questions-boolean-radio {
    margin-bottom: .5em;
    border: none !important;

    &input[type="radio"] {
      /* ...existing styles */
      display: grid !important;
      place-content: center !important;
    }

    &input[type="radio"]::before {
      content: "";
      width: 0.65em !important;
      height: 0.65em !important;
      border-radius: 50% !important;
      transform: scale(0) !important;
      transition: 120ms transform ease-in-out !important;
      box-shadow: inset 1em 1em var(--form-control-color) !important;
    }

    &input[type="radio"]:checked::before {
      transform: scale(1) !important;
    }
  }
}