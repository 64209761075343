.Questionnaires {
  margin: 0 auto;
  text-align: left;
  // padding-left: 1em;
  // padding-right: 1em;

  div {
    text-align: left;
  }

  .form-field {
    box-sizing: border-box;
  }

  // .action-box {
  //   height: 114px;
  //   margin: 31px 0 0;
  //   padding: 31px 20px;
  //   background-color: rgba(235, 237, 247, 0.61);
  //   .save-questions {
  //     width: 80%;
  //     height: 52px;
  //     padding: 9px 32px 19px 30px;
  //     border: none;
  //     border-radius: 30px;
  //     background-color: #fd0;
  //   }
  // }

  .ui.label {
    padding: 0;
    width: 100%;
    margin: 0 auto 12px ;
    text-align: left;
    font-weight: 700;
    font-size: 13px;
    //height: 12px;
    //font-size: 14px;
    // font-weight: normal;
    // font-stretch: normal;
    // font-style: normal;
    // line-height: normal;
    // letter-spacing: 0.25px;
    //color: #767676;
    background-color: transparent !important;


  }

  .ui.label.followup-label {
    font-weight: 500;
  }

  .input-subtitle {
    text-align: left;
    //margin-left: 15px;
    //margin-top: 12px;
    //font-size: 12px;
    color: #767676;
    // letter-spacing: 0.25px;
  }
}