.nav {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    object-fit: contain;
    width: 172px;
    height: 28px;
    margin: 34px auto;
    background-size: 172px 28px;
    background-repeat: no-repeat;

    &.step1 {
        background-image: url("../../../assets/images/progressbar-step-1.svg") !important;
    }

    &.step2 {
        background-image: url("../../../assets/images/progressbar-step-2.svg") !important;
    }

    &.step3 {
        background-image: url("../../../assets/images/progressbar-step-3.svg") !important;
    }

    &.step4 {
        display:none;
    }
}

.nav-back-link {
    //margin-top: 64px;
    display: table-cell !important;
    //height: 48px;
    position: absolute;
    top: 15px;
    padding-left: .8em;
    //left: 35px;
    text-align: left;
    //font-family: EuclidWU;
    font-size: 16px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: var(--primary-black);
    vertical-align: middle;
    border: none;
    background-color: transparent;

    >svg {
        vertical-align: middle;
        color: var(--primary-black);
    }
}