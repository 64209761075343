@import "../../../assets/styles/colors.css";
@import "../../../assets/styles/fonts.css";

button {

  font-family: Roboto;
  font-weight: 500 !important;
  font-style: normal;
  line-height: 1;
  letter-spacing: -.01em;
  font-stretch: normal;
  text-align: center;
  
  &.btn {

    //margin: 2em 2em 4em;
    padding: 1em 3em;
    border-radius: 30px;
    border: none;
    display: inline-block;
    height: 52px;
    width: 100%;
    max-width: 400px;
    background-color: var(--primary-yellow);
    font-size: 16px;
    color: var(--black);
    margin-bottom: 24px;
  
    &:hover {
      cursor: pointer;
    }
  
    &.disabled {
      opacity: 0.7;
      //border-radius: 30px;
      background-color: var(--wu-black-25);
  
      .CTA {
        color: var(--brownish-grey);
      }
    }
  
    &.btn-primary {
      &.login-cta {
        background: var(--black);

        font-family: 'Roboto';
        font-style: normal;
        font-weight: 500;

        color: var(--primary-yellow);
      }
    }
  
    &.btn-secondary {
      border: solid 2px var(--primary-black);
      color: var(--primary-black);
      background-color: white;
  
      // .CTA {
      //   @media (max-width: 330px) {
      //     font-size: 14px !important;
      //   }
      // }
    }
  
  }
  
  // .action-box {
  //   height: 114px;
  //   margin: 31px 0 0;
  //   padding: 31px 20px;
  //   background-color: rgba(235, 237, 247, 0.61);
  //   width: 110%;
  
  //   .save-hours {
  //     width: 80%;
  //     height: 52px;
  //     padding: 9px 32px 19px 30px;
  //     border: none;
  //     border-radius: 30px;
  //     background-color: var(--primary-yellow);
  //   }
  // }

}
