.add-photos {
  .instructions {
    margin: 24px 35px 28px 36px;
    font-family: EuclidWU;
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.5;
    letter-spacing: normal;
    text-align: center;
    color: var(--primary-black);
  }

  .grid {
    width: calc( 148 * 2px + 20px);;
    min-height: calc( 148 * 2px);
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-auto-rows: 148px;
    grid-row-gap: 20px;
    grid-column-gap: 20px;;
    margin: 0 auto 24px;

    .Mask {
      display: grid;
      align-items: center;
      width: 148px;
      height: 148px;
      margin: 0 auto;
      background-color: var(--pale-grey);
      

      .overlay {
        grid-column: 1;
        grid-row: 1;
        left: 0;
        top: 0;
        width: 148px;
        height: 148px;
        z-index: 9999;
        .icon {
          border-radius: 50%;
          float: right;
          color: black;
          background: aliceblue;
          padding: 0;
          margin: 8px;
          cursor: pointer;
        }
      }

      img {
        grid-column: 1;
        grid-row: 1;
        margin: 0 auto;
        width: 148px;
        height: 148px;
        &.pristine {
          width: 42px !important;
          height: 42px !important;
        }
      }
    }
  }
}