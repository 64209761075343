.update-hours {
  width: 90%;
  //font-family: EuclidWU;
  margin: 0 auto;

  .title {
    //font-family: EuclidWU;
    // height: 21px;
    // font-size: 21px;
    // font-weight: 500;
    // font-stretch: normal;
    // font-style: normal;
    // line-height: 1;
    // letter-spacing: normal;
    // text-align: center;
    color: var(--primary-black);
  }

  .subtitle {
    //font-family: EuclidWU;
    // font-size: 14px;
    // font-weight: normal;
    // font-stretch: normal;
    // font-style: normal;
    // line-height: 1.5;
    // letter-spacing: normal;
    // text-align: center;
    // color: var(--primary-black);
    margin-bottom: 38px;
  }

  .day-row {
    //height: 185px;
    margin: 0 auto;
    //border-radius: 37px;
    display: block;
    margin-bottom: 2em;
    padding-bottom: 2em;
    border-bottom: 1px solid var(--pale-grey);

    .day-title {
      display: grid;
      width: 100%;
      margin: 0 auto;
      grid-template-columns: 44% 56%;

      span {
        height: 21px;
        margin: 15px 60px 44px 0;
        font-size: 21px;
        font-weight: 500;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: normal;
        color: var(--primary-black);
      }
    }

    .time-range {
      padding: 5px 0;
      //max-width: 316px;
      width: 100%;
      margin: 0 auto;
      display: grid;
      grid-template-columns: 130px auto 130px;
      //grid-template-columns: 54% 20% 54%;

      .TO {
        grid-column: 2;
        display: block;
        width: 45px;
        height: 40px;
        padding-top: 10px !important;
      }

      .hours-dropdown {
        width: 100% !important;
        border-radius: 20px;
        border: solid 1px #27347d;
        display: block;

        &:nth-child(0) {
          grid-column: 1;
        }

        &:nth-child(2) {
          grid-column: 3;
        }

        .ui.dropdown > .dropdown.icon:before {
          color: var(--secondary-sapphire) !important;
        }
      }
    }

    hr {
      color: var(--pale-grey);
      width: 316px;
    }
  }

  .ui.selection.dropdown {
    min-width: auto !important;
  }
}

// .action-box {
//   height: 114px;
//   margin: 31px 0 0;
//   padding: 31px 20px;
//   background-color: rgba(235, 237, 247, 0.61);
//   width: 110%;

//   .save-hours {
//     width: 80%;
//     height: 52px;
//     padding: 9px 32px 19px 30px;
//     border: none;
//     border-radius: 30px;
//     background-color: var(--primary-yellow);
//   }
// }