@import "../../../assets/styles/fonts.css";
@import "../../../assets/styles/colors.css";

.switch-button {
  display: grid;
  //grid-template-columns: 250px 1fr;
  grid-template-rows: 52px 1fr;

  margin: 0 auto;
  // min-width: fit-content;
  // max-width: 200px;
  width: fit-content;

  .ui.buttons {
    width: 155px !important;
    .ui.button {
      &.left, &.right {
        grid-area: 1 / 1 / 2 / 2;
        border-radius: 2em;
        -webkit-backdrop-filter: blur(5.6px) !important;
        backdrop-filter: blur(5.6px) !important;
        box-shadow: 0 2px 34px 0 rgba(39, 52, 125, 0.17) !important;
        background-color: var(--primary-white) !important;
        color: var(--primary-black) !important;
        padding: 1em 2em 1em 2em;

        &:nth-last-child(odd) {
          grid-column: 1;
          margin-left: -3em;
          padding-left: 4em;
        }

        // &:nth-child(0) {
        //   grid-column: 2;
        //   padding-right: 55px !important;
        // }

        &.active {
          color: var(--primary-black) !important;
          border: solid 1px var(--primary-yellow) !important;
          background-color: var(--primary-yellow) !important;
          z-index: 2;
          padding: 1em 2em 1em 2em;
        }
      }
      &.left:not(.active){
        padding: 1em 4em 1em 2em;
      }
      &.right:not(.active){
        padding: 1em 2em 1em 4em;
      }

    }
  }
  
  // @media (max-width: 330px) {

  //     grid-template-rows: 48px 1fr;
  
  //     // .ui.buttons {
  //     //   width: 152px !important;
  //     //   .ui.button {
  //     //     &.left {
  //     //       width: 75% !important;
  //     //     }
  //     //     &.right {
  //     //       width: 35% !important;
  //     //     }
  //     //   }
  //     // }
  // }


}

