.ui.segment {
    padding: 0 !important;
}

.header {
    display: grid;
    grid-gap: 0;
    grid-template-columns: [col1-start] 88px [col2-start] auto [col3-start] 68px [col3-end];
    grid-template-rows: [row1-start] 64px [row2-start] 64px [row2-end];

    &.login {
        grid-template-rows: [row1-start] 64px [row2-end];
    }

    nav {
        color: gray;
        float: right;
        margin-right: 10px;
    }

    .hamburger {
        color: var(--black);
        float: right;
        height: 33px !important;
        width: 40px !important;
        z-index: 9;
    }

    .box {
        background-color: var(--primary-black);
        color: #fff;
        font-size: 150%;

        &.a {
            grid-column: col1-start;
            grid-row: row1-start ;
            padding: 0px;

            .wulogo {
                left: 0;
                vertical-align: middle;
                align-items: center;
                width: 88px !important;
                height: 64px;
            }
        }
        &.b {
            grid-column: col2-start ;
            grid-row: row1-start;
        }
        &.c {
            grid-column: col3-start;
            grid-row: row1-start ;
            padding: 7px;

            .main-menu {
                position: absolute;
                display: block;
                width: 100%;
                height: 64px !important;
                align-items: center;
                .hamburger-react {
                    cursor: pointer !important;
                    width: 48px !important;
                    height: 48px !important;
                    position: relative !important;
                    transition: all 0.4s cubic-bezier(0, 0, 0, 1) 0s;
                    user-select: none;
                    outline: none;
                    transform: none;
                    margin-top: 10px;
                    margin-right: 10px;
                    float: right;
                }
            }
        }
        &.d {
            grid-column: col1-start/span 3;
            grid-row: row2-start;
            &.wu {
                background-color: var(--primary-black) !important;
            }
            &.vi {
                background-color: blue !important;
            }
            &.ol {
                background-color: red !important;
            }
        }
    }
}
