@import "../../assets/styles/fonts.css";
@import "../../assets/styles/colors.css";

.how-it-works-container {
  background: var(--primary-yellow);
  width: 100%;
  height: 100%;
  display: block;
  box-sizing: unset !important;
  box-suppress: show !important;
}

#how-it-works {
  margin: 0 !important;
  padding: 0 !important;
  position: absolute;
  width: fit-content;
  height: 100% !important;
  top: 0;
  left: 0 !important;
  background: var(--primary-yellow);
  display: block !important;

  .content {
    display: block;
    width: 100%;
    height: fit-content;
    margin: 0 !important;
    padding: 0 !important;



    .Onboarding {
      display: block;
      width: 100%;
      height: 94%;
      background: var(--primary-yellow);
      background-size: 50%;

      &.sliding {
        background: var(--primary-yellow);
        padding-top: 30px;
      }

      .skip {
        text-align: right;
        justify-content: right;
        height: 16px;
        margin-right: 5%;
        font-size: 16px;
        font-weight: 500;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: 1.2px;
        color: var(--primary-black);
        display: block;
        float: right;
        border: none;
        background-color: transparent;
        cursor: pointer;

        &.first {
          justify-content: center !important;
          bottom: 30px !important;
          width: 100%;
          text-align: center;
          display: block;
          height: fit-content;
          margin-bottom: 20px;
        }
      }
    }

    .presentation {
      display: flex;
      flex-direction: column;
      height: 75%;
      min-height: 100vh;
      margin-top: auto;
      .container {
        display: block;
        flex: 1;
        min-height: fit-content;
        .title {
          height: 126px;
          left: 20px;
          top: 366px;
          font-family: PPRightGrotesk !important;
          font-style: normal;
          font-weight: 500;
          font-size: 40px;
          line-height: 42px;
          letter-spacing: -0.625px;
          color: var(--primary-black);
          text-align: center;
          justify-content: center;
          margin: 20px;
        }

        .subtitle {
          height: 84px;
          left: 29px;
          top: 504px;
          margin: 29px;
          font-family: Roboto !important;
          font-style: normal;
          font-weight: 400;
          font-size: 14px;
          line-height: 21px;
          text-align: center;
        }
      }
      .actions {
        display: block;
        flex: 0;
        padding: 0 5% 0;
        .Button {
          bottom: 57px !important;
          display: block;
          width: 100%;
          margin: 0 auto 0 auto;
          > button {
            color: var(--primary-yellow) !important;
            background-color: var(--primary-black) !important;
          }
        }
      }
    }
  } // content
}

.onboarding-image {
  width: 100%;
  height: 320px !important;
  margin: 0 !important;
  clip-path: url(#clipping);
}

.slides {
  display: block;
  width: 100%;
  height: 100%;
  margin: 0 !important;
  padding: 0 !important;
  max-width: 100%;
  max-height: 80vh;

  >div {
    height: 100% !important;
  }

  .autoplay-buttons {
    position: absolute;
    bottom: 0;
    display: block;
    width: 20%;
  }

  .react-slideshow-container {
    display: block !important;
    .react-slideshow-wrapper {
      &.slide {
        &.basic {
          width: 100%;
          overflow: hidden;
          height: 89vh !important;

          .images-wrap {
            height: 100% !important;
          }

          .active {
            display: block !important;
            height: 89% !important;
            min-height: fit-content !important;
          }
        }
      }
    }
  }

  .react-slideshow-container+ul.indicators {
    margin: 0 auto 0 auto !important;
    bottom: 30px !important;
    width: 90% !important;
    position: inherit !important;
    padding: 0 !important;
  }

  .illustration {
    width: 325.2px;
    height: 281px;
    margin: 12px auto 50px auto;
    object-fit: contain;
  }

  .title {
    font-family: PPRightGrotesk !important;
    font-style: normal;
    font-weight: 500;
    font-size: 30px;
    line-height: 34px;
    text-align: center;
    letter-spacing: -0.625px;
    color: var(--primary-black);
    text-transform: none;
    text-justify: distribute-center-last;
  }

  .subtitle {
    margin: 16px 24px 40px 24px;
    font-family: Roboto !important;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 21px;
    text-align: center;
    color: var(--primary-black);
  }

  .Button {
    bottom: 57px !important;
    //position: absolute;
    display: block;
    width: 90%;
    margin: 0 auto 0 auto;
    >button {
      color: var(--primary-yellow) !important;
      background-color: var(--primary-black) !important;
      padding: 0 !important;
    }
  }
}

.ui.page.modals {
  padding: 0 !important;
  scrollbar-width: none !important;
  color: var(--primary-yellow) !important;
  &::-webkit-scrollbar {
    width: 1px !important;
    color: transparent;
    background-color: transparent;
  }
  &.basic {
    background-color: var(--primary-yellow) !important;
    overflow: hidden;
  }
}

.ui.fullscreen.modal{
  width: 100% !important;
}

.ui.dimmer {
  padding: 0;
  margin: 0;
}

@media only screen and (min-height: 700px) {
  .slides {
    padding: 144px 28px 28px 28px !important;
    .react-slideshow-container {
      .react-slideshow-wrapper {
        &.slide {
          &.basic {
            width: 100%;
            overflow: hidden;
            height: 78vh !important;

            .active {
              display: block !important;
              height: 78% !important;
              min-height: fit-content !important;
            }
          }
        }
      }
    }
  }
}
