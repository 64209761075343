.questions-rating {
  //border: 1px solid yellow;
  // width: 80%;
  // margin: 20px auto 20px auto;
  // text-align: left !important;
  margin-bottom: 24px;

  .rating-stars {
    margin: 0 auto 12px;
    .ui.star.rating {
      font-size: 4rem !important;
      justify-content: space-between;
      width:100%;
      .icon {
        color: rgba(255,221,0,0.25) !important;
      }
      .active.icon, &:hover, .ui.star.rating, .icon.selected, .icon.selected.active {
        color: var(--primary-yellow) !important;
        background-color: #fff !important;
        text-shadow: 0px -1px 0px var(--primary-yellow), -1px 0px 0px var(--primary-yellow), 0px 1px 0px var(--primary-yellow), 1px 0px 0px var(--primary-yellow) !important;
      }
    }
  }
  .labels {
    width: 100%;
    margin: 0 auto;
    height: 16px;
    .to-left {
      float: left;
    }
    .to-right {
      float: right;
    }
  }
}