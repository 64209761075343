@import "../../assets/styles/fonts.css";
@import "../../assets/styles/colors.css";

.login-container {
  width: 100%;
  background: var(--primary-yellow);
  color: var(--primary-black);
  height: 100vh;
  padding: 0 20px;
  text-align: left;
  font-family: PPRightGrotesk, sans-serif;
  display: grid;
  grid-template-rows: 182px 98px 76px 1fr;
  column-count: 1;
  min-height: max-content;
  max-height: 81vh;

  h1.head {

    font-style: normal;
    font-weight: 500;
    font-size: 40px;
    line-height: 44px;
    /* or 104% */
    letter-spacing: -0.48px;
    padding-top:36px;
    width: 89%;
  }

  .thanks {
    // width: 335px;
    // height: 52px;
    // left: 20px;
    // top: 322px;

    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 26px;
    width: 89%;
    /* or 130% */
    letter-spacing: -0.15px;

    a {
      text-decoration: underline;
    }
  }

  .register {
    //text-align: center;
    a {
      color: var(--wu-black);
      text-decoration: underline;
    }
    margin-bottom: 20px;
  }

  //.rectangle {
  //  width: 944px;
  //  height: 403px;
  //
  //  display: contents;
  //  border: dashed 1px black;
  //
  //  background: var(--primary-yellow);
  //  //transform: skew(-20deg);
  //
  //
  //}

  .login-image {
    width: 102vw;
    max-width: 600px;
    position: relative;
    left: -26px;
    bottom: 0 !important;
  }


}
footer {
  display: block;
  background: var(--primary-black);
  //position: absolute;
  margin: 0;
  padding: 15px;
  //left: 0;
  //bottom: 0;
  //height: 120px;
  width: 100%;
  text-align: left;
  //position: absolute;
  bottom: 0 !important;
  >p {
    color: var(--primary-white);
    margin: 5px 0 0 0;
    font-family: Roboto;
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 21px;

    >a {
      color: var(--primary-white) !important;
    }

    &.copy {
      font-style: normal;
      font-weight: 400;
      font-size: 10px;
      line-height: 21px;
      letter-spacing: -0.208333px;
      color: var(--primary-white);
    }

  }

  .ot-sdk-show-settings {
    color: var(--primary-white) !important;
    border: none;
    background: none;
    padding: 0;
    font-size: 12px;
    cursor: pointer;
    color: var(--primary-white);
  }
}

.mask {
  display: block;
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 1099;
  opacity: 0.2;
  background-color: var(--secondary-sapphire);

  &.disabled {
    z-index: -9 !important;
    visibility: hidden !important;
  }
}

#okta-sign-in.auth-container.main-container {
  border: none !important;
}

// Page Properties
$fonts: Roboto, Arial, Helvetica, sans-serif;
$default-border-radius: 0px;
$container-width: 400px;
$container-min-width: 300px;
$num-tabs: 5;

// Font Size
$font-size: 13px;
$font-size-small: 12px;
$font-size-header: 14px;
$font-size-footer: 11px;
$font-size-default: 14px;

// Font Weight
$font-weight-body: normal;
$font-weight-header: bold;

// Theme Color
$primary-bg-color: #fff;
$secondary-bg-color: #f9f9f9;

// Text Color
$header-text-color: #5e5e5e;
$dark-text-color: #5e5e5e;
$medium-text-color: #777;
$light-text-color: #a7a7a7;
$link-text-color: #fd0;
$placeholder-text-color: #777777;
$error-text-color: #e34843;

// Button Color
$icon-button-bg-color: #fff;
$button-bg-color: #fbfbfb;
$dark-button-bg-color: #929292;
$primary-button-bg-color: #fd0;
$success-button-bg-color: #4cbf9c;
$negative-button-bg-color: #c35151;
$disabled-button-text-color: #262626;
$disabled-primary-button-text-color: #262626;
$disabled-primary-button-bg-color: #ebedf7;
$disabled-primary-border-color: #ebedf7;

// Social Auth Button Colors
//$facebook-icon-bg-color: #304879; // fb brand color - #3B5998
//$facebook-label-bg-color: #406094;
//$facebook-border-color: #375685;
//$google-icon-bg-color: #d43722; // google brand color - #DC4E41
//$google-label-bg-color: #de4937;
//$google-border-color: #b63c33;
//$linkedin-icon-bg-color: #04659a; // linkedin brand color - #0077B5
//$linkedin-label-bg-color: #0077b5;
//$linkedin-border-color: #0f608f;
//$microsoft-icon-bg-color: #4294de; // microsoft brand color - #00A1F1
//$microsoft-label-bg-color: #00a1f1;
//$microsoft-border-color: #447199;

//Default Custom Button Colors
//$default-custom-button-background-color: #ff5f73;
//$default-custom-button-border-color: #d6001a;

// Button Size
$input-height: 40px;
$button-height: 50px;
$button-line-height: $input-height - 4px;
$button-padding: 0 15px;

// Input field
$input-bg-color: #fff;
$input-border-color: #bbb;
$input-border-color-hover: #888;
$input-border-color-focus: #fd0;

// Modal
$modal-bg-color: #f9f9f9;

// Footer

// Other

// @import 'variables';
//@import 'helpers';

@mixin light-button-template($bg-color) {
  //@include button-template($bg-color, 1%, $dark-text-color, 22%, 25%);
  //@include button-shadow(0.05);
  &:hover,
  &:focus,
  &:active,
  &:focus {
    /* -- Buttons' Colors -- */
    box-shadow: none;
  }
}

@mixin dark-button-template($bg-color) {
  //@include button-template($bg-color, 3%, #fff, 15%, 17%);
  //@include button-shadow(0.15);
  &:hover,
  &:focus,
  &:active,
  &:focus {
    /* -- Buttons' Colors -- */
    box-shadow: none;
  }
}

@mixin button-shadow($drop-shadow-opacity) {
  box-shadow: rgba(#000, $drop-shadow-opacity) 0 1px 0, rgba(255, 255, 255, 0.1) 0 1px 0 0 inset;
}

@mixin button-template($bg-color, $gradient-factor, $text-color, $border-factor, $border-bottom-factor) {
  color: $text-color;
  background-color: $bg-color;
  //background: -o-linear-gradient(top, $bg-color 0%, darken($bg-color, $gradient-factor) 100%); /* Opera 11.10+ */
  //background: -ms-linear-gradient(top, $bg-color 0%, darken($bg-color, $gradient-factor) 100%); /* IE10+ */
  //background: linear-gradient($bg-color, darken($bg-color, $gradient-factor));
  border-color: darken($bg-color, $border-factor);
  border-bottom-color: darken($bg-color, $border-bottom-factor);

  &:hover,
  &:focus,
  &:active,
  &:focus {
    /* -- Buttons' Colors -- */
    $hover-bg-color: lighten($bg-color, 3%);
    background-color: $hover-bg-color;
    //background: -o-linear-gradient(top, $hover-bg-color 0%, darken($hover-bg-color, $gradient-factor) 100%); /* Opera 11.10+ */
    //background: -ms-linear-gradient(top, $hover-bg-color 0%, darken($hover-bg-color, $gradient-factor) 100%); /* IE10+ */
    //background-image: linear-gradient($hover-bg-color, darken($hover-bg-color, $gradient-factor));
    border-color: darken($bg-color, $border-factor * 1.3);
    border-bottom-color: darken($bg-color, $border-bottom-factor * 1.2);
    text-decoration: none;
    cursor: pointer;
  }
}


// scss-lint:disable IdSelector
#okta-sign-in.auth-container {
  // scss-lint:enable IdSelector
  // border: none !important;
  // height: 90vh;
  // width: 450px;
  // text-align: left !important;


  .auth-content {
    // display: block;
    // height: 90vh !important;
    // top: 100px !important;
    // margin: 0 auto;
    // width: 90%;
    padding: 0;
  }

  //main {
  //  height: 90vh !important;
  //  top: 100px !important;
  //  margin: 0 auto;
  //  width: 90%;
  //}

  // &.main-container {
  //   /* -- Fonts and Text Colors -- */
  //   font-family: $fonts;
  //   color: $medium-text-color;
  //   border: none !important;
  // }

  // h2,
  // h3 {
  //   /* -- Fonts and Text Colors -- */
  //   font-family: EuclidWU;
  //   font-size: 21px;
  //   font-weight: 500;
  //   font-stretch: normal;
  //   font-style: normal;
  //   line-height: 1.29;
  //   letter-spacing: -0.44px;
  //   text-align: center;
  //   color: var(--primary-black);
  // }

  .okta-sign-in-header {
    /* -- Fonts and Text Colors -- */
    color: $header-text-color;
    display: none !important;
  }

  .okta-form-subtitle,
  .okta-form-label {
    /* -- Fonts and Text Colors -- */
    color: $medium-text-color;
    text-align: left !important;
    font-weight: 400;
  }

  .link {
    /* -- Fonts and Text Colors: links -- */
    color: $medium-text-color;

    &:link,
    &:visited,
    &:hover,
    &:active {
      /* -- Fonts and Text Colors: links -- */
      color: $medium-text-color;
    }
  }

  .inline-link {
    /* -- Fonts and Text Colors: links -- */
    color: $link-text-color;

    &:link,
    &:visited,
    &:hover,
    &:active {
      /* -- Fonts and Text Colors: links -- */
      color: $link-text-color;
    }
  }

  input[type="submit"],
  input[type="button"] {
    /* -- Submit Buttons' Fonts -- */
    font-family: $fonts;
  }


  &.main-container {
    /* -- Main Background and Border Colors -- */
    background-color: $primary-bg-color;
    //border-color: #ddd #ddd #d8d8d8;
    //box-shadow: 0 2px 0 rgba(175, 175, 175, 0.12);
    width: 100% !important;

    //@include mobile {
    //  border-width: 0;
    //  box-shadow: none;
    //}

    .bg-helper {
      /* -- Main Background and Border Colors -- */
      /* set the helper's color value to the same color as the main-container's background */
      color: $primary-bg-color;
    }
  }

  .okta-sign-in-header {
    /* -- Main Background and Border Colors -- */
    border-bottom-color: #ddd;
  }

  .okta-sign-in-beacon-border {
    /* -- Main Background and Border Colors -- */
    border-color: #a7a7a7;
  }

  .okta-form-divider {
    /* -- Main Background and Border Colors -- */
    border-bottom-color: #e8e8e8;
  }

  .factor-icon,
  .qrcode-image {
    /* -- Main Background and Border Colors -- */
    border-color: #ccc;
  }

  .button {
    /* -- Buttons' Colors -- */
    //@include light-button-template($button-bg-color);
    background-color: $input-border-color-focus !important;
    &:focus {
      border-color: $input-border-color-focus;
    }
  }

  .button-dark {
    /* -- Buttons' Colors -- */
    //@include dark-button-template($dark-button-bg-color);
  }

  .button-primary {
    color: var(--primary-black) !important;
    background: $input-border-color-focus !important;
    border-radius: 30px !important;
    border: none !important;
    height: 52px !important;
    //width: 90% !important;
  }

  .button-success {
    /* -- Buttons' Colors -- */
    //@include dark-button-template($success-button-bg-color);
  }

  .button.link-button-disabled {
    /* -- Buttons' Colors -- */
    color: $disabled-button-text-color;

    &:hover,
    &:focus,
    &:active,
    &:focus {
      /* -- Buttons' Colors -- */
      cursor: default;
      background-image: none;
    }
  }

  .button.button-primary.link-button-disabled {
    /* -- Buttons' Colors -- */
    color: var(--primary-black) !important;
    background: var(--wu-black-25) !important;
    border-radius: 30px !important;
    border: none !important;
    height: 52px !important;
    //width: 90% !important;


    &:hover,
    &:focus,
    &:active,
    &:focus {
      /* -- Buttons' Colors -- */
      cursor: default;
      background-image: none;
    }
  }

  //.icon-button {
  //  /* -- Buttons' Colors -- */
  //  @include button-template($icon-button-bg-color, 0%, $dark-text-color, 23%, 26%);
  //}


  .okta-dropdown-list {
    /* -- Dropdown Colors -- */
    /* background: #fff; */
    border-color: $input-border-color-hover;
  }

  .factors-dropdown-wrap .okta-dropdown-list {
    /* -- Dropdown Colors -- */
    // scss-lint:disable DuplicateProperty
    border-color: #ccc;
    border-color: rgba(0, 0, 0, 0.2);
    // scss-lint:disable DuplicateProperty
  }

  .okta-dropdown-option {
    /* -- Dropdown Colors -- */
    /* background: transparent; */
    /* border-bottom-color: #fff; */

    &:hover {
      /* -- Dropdown Colors -- */
      /* background: #f9f9f9; */
    }

    &.dropdown-list-title:hover {
      /* -- Dropdown Colors -- */
      background: transparent;
    }

    a {
      /* -- Dropdown Colors -- */
      color: $dark-text-color;
    }
  }


  .okta-infobox-error {
    /* -- Error Infobox Colors -- */
    /* background-color: #fff; */
    /* border: 1px solid #ddd; */

    &:before {
      /* -- Error Infobox Colors -- */
      /* background-color: #e34843; */
    }

    .error-16:before {
      /* -- Error Infobox Colors: error icon -- */
      /* color: #fff; */

      /* Use the rules below to replace an error icon in error infoboxes */
      /* content: ''; */
      /* width: 16px; */
      /* height: 16px; */
      /* background-image: none; */
    }

  }

  .enroll-required-factor-list {
    .enroll-factor-row-min .enroll-factor-description {
      h3 {
        /* -- Enroll required factors, factors that are not the current step -- */
        color: $light-text-color;
      }
    }

  }

  .okta-form-input-field {
    /* -- Input Fields -- */
    background-color: $input-bg-color;
    //border-color: $input-border-color;
    border: none !important;
    //border-bottom: 1px solid #777777 !important;

    // &:hover {
    //   /* -- Input Fields -- */
    //   border-color: $input-border-color-hover;
    // }

    &.focused-input {
      //border-color: $input-border-color-focus;
    }

    input {
      /* -- Input Fields -- */
      //font-family: $fonts;
      color: $dark-text-color;
      border-bottom: 1px solid #777777 !important;
      border-radius: 0 !important;

      &::-webkit-input-placeholder {
        /* -- Input Fields: placeholder -- */
        font-family: $fonts;
        color: $placeholder-text-color;
      }

      &::-moz-placeholder {
        /* -- Input Fields: placeholder -- */
        font-family: $fonts;
        color: $placeholder-text-color;
      }

      &:-ms-input-placeholder {
        /* -- Input Fields: placeholder -- */
        font-family: $fonts;
        color: $placeholder-text-color;
      }

      &[disabled] {
        /* -- Input Fields: disabled input field -- */
        color: $medium-text-color;
        -webkit-text-fill-color: $medium-text-color;
        /* background: #f4f4f4; */
      }

      &[type="password"] {
        /* -- Input Fields: password input field -- */
        font-family: Verdana, sans-serif;
      }

    }

    placeholder {
      /* -- Input Fields: placeholder -- */
      font-family: $fonts;
      color: $placeholder-text-color;
    }
  }

  .input-icon:before,
  .input-tooltip:before {
    /* -- Input Fields: icons -- */
    color: $light-text-color;
  }

  .okta-form-label-inline {
    /* -- Input Fields -- */
    /* background-color: #f2f2f2; */
    /* border-color: #c8c8c8; */
    /* color: #999; */
  }

  .okta-form-input-error {
    /* -- Input Fields: error highlight -- */
    /* color: #d93934; */

    .error-16-small:before {
      /* -- Input Fields: error icon -- */
      /* color: #e34843; */

      /* Use the rules below to replace input fields error icon */
      /* content: ''; */
      /* width: 16px; */
      /* height: 16px; */
      /* background-image: none; */
    }
  }


  .chzn-single {
    /* -- Input Fields: Select Input -- */
    background-color: $input-bg-color;
    border-color: $input-border-color;

    span {
      /* -- Input Fields: Select Input -- */
      color: $medium-text-color;
    }

    &:hover {
      /* -- Input Fields: Select Input -- */
      border-color: $input-border-color-hover;
    }
  }

  .chzn-with-drop {
    .chzn-single,
    .chzn-drop {
      /* -- Input Fields: Select Input -- */
      background-color: $input-bg-color;
      border-color: $input-border-color-hover;
    }

    .chzn-results {

      li {
        /* -- Input Fields: Select Input -- */
        /* border-color: #fff; */
      }

      .highlighted {
        /* -- Input Fields: Select Input -- */
        /* background-color: #f9f9f9; */
      }
    }
  }

  .primary-auth { /* -- Page specific top level selectors -- */ }
  .password-expired { /* -- Page specific top level selectors -- */ }
  .password-reset { /* -- Page specific top level selectors -- */ }
  .password-reset-email-sent { /* -- Page specific top level selectors -- */ }
  .forgot-password { /* -- Page specific top level selectors -- */ }
  .account-unlock { /* -- Page specific top level selectors -- */ }
  .account-unlock-email-sent { /* -- Page specific top level selectors -- */ }
  .recovery-challenge { /* -- Page specific top level selectors -- */ }
  .recovery-loading { /* -- Page specific top level selectors -- */ }
  .recovery-question { /* -- Page specific top level selectors -- */ }
  .refresh-auth-state { /* -- Page specific top level selectors -- */ }

  .enroll-choices { /* -- Page specific top level selectors -- */ }
  .enroll-totp { /* -- Page specific top level selectors -- */ }
  .barcode-totp { /* -- Page specific top level selectors -- */ }
  .activate-totp { /* -- Page specific top level selectors -- */ }
  .enroll-manual-totp { /* -- Page specific top level selectors -- */ }
  .barcode-push { /* -- Page specific top level selectors -- */ }
  .activate-push { /* -- Page specific top level selectors -- */ }
  .enroll-manual-push { /* -- Page specific top level selectors -- */ }
  .enroll-activation-link-sent { /* -- Page specific top level selectors -- */ }
  .enroll-symantec { /* -- Page specific top level selectors -- */ }
  .enroll-rsa { /* -- Page specific top level selectors -- */ }
  .enroll-onprem { /* -- Page specific top level selectors -- */ }
  .enroll-duo { /* -- Page specific top level selectors -- */ }
  .enroll-sms { /* -- Page specific top level selectors -- */ }
  .enroll-call { /* -- Page specific top level selectors -- */ }
  .enroll-question { /* -- Page specific top level selectors -- */ }

  .mfa-verify-duo { /* -- Page specific top level selectors -- */ }
  .mfa-verify { /* -- Page specific top level selectors: all challenge pages except duo -- */ }
  .mfa-verify-totp { /* -- Page specific top level selectors -- */ }
  .mfa-verify-push { /* -- Page specific top level selectors -- */ }
  .mfa-verify-totp-inline { /* -- Page specific top level selectors -- */ }
  .mfa-verify-question { /* -- Page specific top level selectors -- */ }
  .mfa-verify-passcode { /* -- Page specific top level selectors -- */ }
  .mfa-verify-password { /* -- Page specific top level selectors -- */ }

}


.okta-sign-in-tooltip.qtip-custom {
  /* -- Tooltips -- */
  font-family: $fonts;
  /* background-color: #4d4d4d; */
  /* border-color: #303030; */

  .qtip-content {
    /* -- Tooltips -- */
    /* color: #f3f3f3; */
  }

  .qtip-titlebar {
    /* -- Tooltips -- */
    /* background-color: #404040 */
  }
}

.okta-sign-in-security-image-tooltip.qtip-custom {
  /* -- Tooltips -- */
  font-family: $fonts;
  /* background-color: #4d4d4d; */
  /* border-color: #303030; */

  .qtip-content {
    /* -- Tooltips -- */
    /* color: #f3f3f3; */
  }
}

// Custom login sections
#okta-sign-in .o-form .custom-checkbox label, #okta-sign-in .auth-footer {
  text-align: left !important;
}

.login-head {
  margin: 0px auto 24px;
  //margin-top: calc(64px - 1em);
  //h1 {padding-top: 22px;}
  h1 {margin-bottom:12px;}
  // h3 {
  //   height: 21px;
  //   margin: 12px 34px 37px;
  //   font-size: 14px;
  //   font-weight: normal;
  //   font-stretch: normal;
  //   font-style: normal;
  //   line-height: 1.5;
  //   letter-spacing: normal;
  //   text-align: center;
  //   color: var(--primary-black);
  // }
  // .subtitle {
  //   margin-bottom: 16px;
  // }
}

.login-foot {
  &.foot-container {

    //font-family: EuclidWU;
    z-index: 9;
    display: block;
    width: 100%;
    margin: 24px auto 0;
    //display: grid;
    //position: fixed;
    //bottom: 1rem;
    //left: 0px;
    padding-top: 1em;
    padding-bottom: 1em;
    border-top: 1px solid var(--brown-grey);
    
    a {
      color: var(--secondary-sapphire);
    }

    .line-one {
      grid-row: 1;
      margin-bottom: 12px
    }

    .line-two {
      grid-row: 2;
      margin-bottom: 12px;
    }

    .login-copy {
      z-index: 9;
      grid-row: 3;
      font-size: 12px !important;
      // height: 21px;
      // margin: 5px 52px 0 51px;
      // font-size: 10px;
      // font-weight: 300;
      // font-stretch: normal;
      // font-style: normal;
      // line-height: 2.1;
      // letter-spacing: -0.21px;
      // color: var(--primary-black);
    }

    button {
      color: var(--secondary-sapphire) !important;
      border: none;
      background:none;
      padding:0;
    }

  }
}

// .login-widget-container {
//   height: 60vh !important;
// }

// @media (max-height: 568px) {
//   .login-widget-container {
//     height: 90vh !important;
//   }
// }

// @media (max-height: 736px) {
//   .login-foot {
//     margin: 73px auto 0 auto !important;
//   }
// }

// @media (max-width: 380px) {
//   .login-head {
//     margin-top: 60px !important;
//   }
// }