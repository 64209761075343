.questions-scale {
  // width: 80%;
  // margin: 0 auto;
  // text-align: left !important;
  margin-bottom: 24px;

  .to-left {
    float: left;
  }

  .to-right {
    float: right;
  }

  .ui.input {
    width: 100% !important;
  }
  input[type=range] {
    width: 100%;
    margin: 8.5px 0;
    background-color: transparent;
    -webkit-appearance: none;
  }
  input[type=range]:focus {
    outline: none;
  }
  input[type=range]::-webkit-slider-runnable-track {
    background: var(--primary-yellow);
    border: 1px solid #010101;
    border-radius: 25px;
    width: 100%;
    height: 5px;
    cursor: pointer;
  }
  input[type=range]::-webkit-slider-thumb {
    margin-top: -9.5px;
    width: 32px;
    height: 22px;
    background: var(--primary-black);
    border: 2px solid #ffffff;
    border-radius: 14px;
    cursor: pointer;
    -webkit-appearance: none;
  }
  input[type=range]:focus::-webkit-slider-runnable-track {
    background: var(--primary-yellow);
  }
  input[type=range]::-moz-range-track {
    background: var(--primary-yellow);
    border: 1px solid var(--primary-black);
    border-radius: 25px;
    width: 100%;
    height: 5px;
    cursor: pointer;
  }
  input[type=range]::-moz-range-thumb {
    width: 32px;
    height: 22px;
    background: var(--primary-black);
    border: 2px solid #ffffff;
    border-radius: 14px;
    cursor: pointer;
  }
  input[type=range]::-ms-track {
    background: transparent;
    border-color: transparent;
    border-width: 10.3px 0;
    color: transparent;
    width: 100%;
    height: 5px;
    cursor: pointer;
  }
  input[type=range]::-ms-fill-lower {
    background: var(--primary-yellow);
    border: 1px solid #010101;
    border-radius: 50px;
  }
  input[type=range]::-ms-fill-upper {
    background: var(--primary-yellow);
    border: 1px solid #010101;
    border-radius: 50px;
  }
  input[type=range]::-ms-thumb {
    width: 32px;
    height: 22px;
    background: var(--primary-black);
    border: 2px solid #ffffff;
    border-radius: 14px;
    cursor: pointer;
    margin-top: 0px;
    /*Needed to keep the Edge thumb centred*/
  }
  input[type=range]:focus::-ms-fill-lower {
    background: var(--primary-yellow);
  }
  input[type=range]:focus::-ms-fill-upper {
    background: var(--primary-yellow);
  }
  /*TODO: Use one of the selectors from https://stackoverflow.com/a/20541859/7077589 and figure out
  how to remove the virtical space around the range input in IE*/
  @supports (-ms-ime-align:auto) {
    /* Pre-Chromium Edge only styles, selector taken from hhttps://stackoverflow.com/a/32202953/7077589 */
    input[type=range] {
      margin: 0;
      /*Edge starts the margin from the thumb, not the track as other browsers do*/
    }
  }
}

@media screen and (max-width: 390px) {
  .ui.rating .icon {
    font-size: 2.5rem !important;
  }
}