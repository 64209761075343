@import "../../assets/styles/fonts.css";
@import "../../assets/styles/colors.css";

// .location-container-map {
//   .address-autocomplete-container {
//     margin-left: 5% !important;
//     margin-right: 5% !important;
//   }
// }

.address-autocomplete-container {
  display: block;
  width: 100%;
  z-index: 98;
  position: relative;
  padding-top: 1em;
  padding-bottom: 1em;
  //top: 5em; */
  //margin: 0px auto;

  .address-autocomplete {
    z-index: 99;
    height: 58px;
    //width: 90%;
    margin: 5px auto 0 auto;
    padding: 10px 22px 9px 22px;
    border-radius: 46px;
    box-shadow: 0 2px 34px 0 rgb(45 78 157 / 15%);
    background-color: var(--primary-white);
    display: grid;
    grid-template-columns: 7% 86% 7%;
    vertical-align: center;
    //font-family: EuclidWU;

    ._search {
      grid-column: 1;
      margin-top: 5px;
      width: 24px;
      object-fit: contain;
      position: relative;
      z-index: 100;
      display: block;
      vertical-align: center;
      color: var(--primary-black);
    }

    ._input {
      grid-column: 2;
      background-color: var(--primary-white);
      margin: 10px;
      font-size: 16px;
      border: none;
      z-index: 99;
      width: 100%;
    }
  }

  .Combined-Shape {
    grid-column: 3;
    vertical-align: center;
    position: relative;
    margin-top: 7px;
    border-radius: 3px;
    color: var(--primary-black);
    background-color: var(--primary-white);
    z-index: 100;
    display: block;
    cursor: pointer;
    margin-right: 10px;
    margin-left: 10px;

    .location-label {
      padding: 5px;
      color: var(--secondary-sapphire);
    }

    svg {
      margin: 5px;
    }

    &.close {
      color: var(--primary-black);
    }
  }
}

// @media (max-width: 540px) {
//   .location-container-map {
//     .address-autocomplete-container {
//       margin-right: 5% !important;
//       margin-left: auto !important;
//     }
//   }

//   .address-autocomplete-container {
//     // display: block;
//     // width: 100%;
//     // z-index: 98;
//     // position: absolute;

//     .address-autocomplete {
//       margin-left: auto !important;
//     }
//   }
// }