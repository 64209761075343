.brand-select {
    width: 100%;
    display: block;
    height: 100%;
    &.wu {
        background-color: var(--primary-yellow);
    }
    &.vi {
        background-color: blue;
    }
    &.ol {
        background-color: red;
    }

    &.small {
        float: left;
    }

    &.medium {
        float: left;
    }

    &.large {
        margin: 0 auto 0 auto !important;
    }

    .btn {
        width: 100% !important;
    }

    .text {
        font-size: 16px;
        line-height: 1.4;
        letter-spacing: -.01;
    }

    // >.ui.fluid.search.selection.dropdown {
    //     height: 30px !important;
    //     //margin-top: 10px;
    //     top: 15px;
    // }

    >.ui.fluid.selection.dropdown {
        top: 12px;
        text-align: left !important;
        height: 40px !important;
        max-height: 40px !important;
        min-height:34px;
        border-radius: 20px;
        border: solid 1px #27347d;
        display: block;
        min-width: 200px !important;
        z-index: 99999 !important;
        width: 90%;
        margin: 0 auto;
        padding: 8px;
    }

    >.ui.label {
        text-align: left !important;
    }

    .ui.selection.active.dropdown .menu {
        max-height: 150px;
    }


    .ui.search.selection.dropdown>input.search {
        padding: .25em 1em;
    }
    .ui.selection.dropdown>.dropdown.icon {
        padding: 8px;
    }
}

.brand-name {
    float: left;
    margin: 24px 0 24px 24px;
    color: white;
    font-weight: bolder;

    &.wu {
        color: var(--primary-black);
    }
    &.vi {
        color: var(--primary-white);
    }
    &.ol {
        color: var(--primary-white);
    }
}

.change-brand {
    float: right;
    margin: 24px 24px 24px 0;
    font-weight: 500;
    text-decoration: underline;
    background-color: transparent;
    border: none;
    font-size: 14px;
    cursor: pointer;
    &.wu {
        color: var(--primary-black);
    }
    &.vi {
        color: var(--primary-white);
    }
    &.ol {
        color: var(--primary-white);
    }
}

.brand-logo {
    display: block;
    margin: 0 !important;
    padding: 0 !important;
    width: 50px;
    height: 50px;
    background-color: var(--primary-black);
    img {
        z-index: 99999;
        display: block;
        width: 50px !important;
        height: 50px !important;
        //background-color: var(--primary-black) !important;
    }
}